import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
} from '@fortawesome/free-solid-svg-icons'


import Layout from "../components/layout"
import SEO from "../components/seo"

const PublicationsPage = () => (
  <Layout>
    <SEO title="Publications" description="Publications from the Shift Power for Peace project." />

    <div className="container mx-auto mt-4 md:mt-16">
      <div className="md:flex p-4 md:p-0">
        <div className="md:w-1/3 mb-4 md:mb-0">
          <h1 className="text-3xl font-bold text-brand-purple">Publications</h1>
        </div>
        <div className="md:border-l-2 md:border-brand-purple md:pl-8 md:w-2/3 md:pb-16">
          <div className="flex">
            <div className="hidden md:flex w-100 mr-4">
              <StaticImage
                width={200}
                height={300}
                layout="fixed"
                alt=""
                src="../images/pioneering-peace-cover.png"
              />
            </div>
            <div>
              <h2 className="text-2xl font-bold text-brand-purple">Pioneering Peace: Digital Inclusion and Adaptation in Response to COVID-19</h2>
              <div className="text-s text-brand-pink mb-2">April 2021</div>
              <p className="text-xl text-brand-purple mb-4">COVID-19 has hastened our use of technology and moved our lives almost wholly online. We have become more reliant on technology in everyday life. For peacebuilders, their experiences have been similar.</p> 
              <p className="text-xl text-brand-purple mb-4">In 2020, Shift Power for Peace launched the Digital Inclusion Fund to support local communities across the world, with funding to pivot their work online.</p>
              <p className="text-xl text-brand-purple mb-4">Our new report, Pioneering Peace, describes the successful impact of the programme and details our lessons, challenges and opportunities for the future.</p>
              <p className="text-xl text-brand-purple mb-4">We also set out a series of key recommendations for future digital support for communities preventing war and conflict.</p>
              <p className="text-xl text-brand-purple mb-4">The report is available in English, French, Spanish and Arabic.</p>
              <div className="flex mt-4"><a href={'/Pioneering-Peace-Report_en.pdf'} target="_blank" className="items-center px-4 py-3 space-x-3  font-bold text-brand-purple bg-brand-yellow rounded-lg sm:flex hover:bg-brand-yellow-light focus:outline-none focus:bg-brand-yellow-light mr-4"><span className="mr-2"><FontAwesomeIcon icon={faDownload} size="1x" /></span> Read the report in English</a> <a href={'/Pioneering-Peace-Report_fr.pdf'} target="_blank"  className="items-center px-4 py-3 space-x-3  font-bold text-brand-purple bg-brand-yellow rounded-lg sm:flex hover:bg-brand-yellow-light focus:outline-none focus:bg-brand-yellow-light"><span className="mr-2"><FontAwesomeIcon icon={faDownload} size="1x" /></span> Lire le rapport en français</a></div>
              <div className="flex mt-4"><a href={'/Pioneering-Peace-Report_es.pdf'} target="_blank"  className="items-center px-4 py-3 space-x-3  font-bold text-brand-purple bg-brand-yellow rounded-lg sm:flex hover:bg-brand-yellow-light focus:outline-none focus:bg-brand-yellow-light mr-4"><span className="mr-2"><FontAwesomeIcon icon={faDownload} size="1x" /></span> Lea el informe en español</a> <a href={'/Pioneering-Peace-Report_ar.pdf'} target="_blank" className="items-center px-4 py-3 space-x-3  font-bold text-brand-purple bg-brand-yellow rounded-lg sm:flex hover:bg-brand-yellow-light focus:outline-none focus:bg-brand-yellow-light"><span className="mr-2"><FontAwesomeIcon icon={faDownload} size="1x" /></span> اقرأ التقرير بالعربية</a></div>
            </div>
          </div>
          
        </div>
      </div>      
    </div>
  </Layout>
)

export default PublicationsPage
